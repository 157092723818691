<template>
  <div class="contents">
    <audio
      id="bgmSound"
      :src="`${commonPath}sounds/bgm.mp3`"
      loop="true"
    />
    <div class="top-menu">
      <img
        :src="`${commonPath}${menuContent.logoPath}`"
      >
      <div class="top-menu-right">
        <div
          class="menu-button"
          @click="openNewWindow(menuContent.docUrl)"
        >
          <div class="button-content box-btn">
            <!-- <i class="fas fa-angle-right border-icon"></i> -->
            <i class="fas fa-home" />
            <div>ホーム</div>
          </div>
        </div>
        <div
          class="menu-button"
          @click="openNewWindow(menuContent.contactUrl)"
        >
          <div class="button-content box-btn">
            <!-- <i class="fas fa-info border-icon"></i> -->
            <i class="fas fa-question" />
            <div>使い方</div>
          </div>
        </div>
        <div
          class="menu-button "
          @click="(bgmStatus) ? setBgmStatus(false) : setBgmStatus(true);"
        >
          <template v-if="bgmStatus">
            <div class="button-content circle-btn volume--on">
              <i class="fas fa-volume-up" />
              <div>ON</div>
            </div>
          </template>
          <template v-else>
            <div class="button-content circle-btn volume--off">
              <i class="fas fa-volume-mute" />
              <div>OFF</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="vr-scene">
      <a-scene id="scene">
        <a-assets
          v-if="pageId === undefined"
          id="assets-area"
        >
          <video
            v-for="(face, index) in faceList"
            :id="index"
            :key="index"
            :src="`/face/${face}`"
            preload="auto"
            playsinline
            autoplay
            muted
            loop
          />
        </a-assets>
        <RoomScene
          ref="room"
          :image-route-path="routePath + 'images/'"
          :glb-route-path="routePath + 'glb/'"
          :json-data="jsonData"
          @setGlbData="setGlbData"
          @loadFlag="loadFlag = true"
          @setConfirmWindow="setConfirmWindow"
        />
        <!-- 室内の場合の2Fの床 -->
        <a-plane
          v-if="jsonData.modelName === 'inside'"
          id="inside-prevFloor"
          static-body
          position="7.5 3.2 13"
          rotation="270 0 0"
          width="5"
          height="5"
          material="opacity:0"
        />
        <!-- 屋外の場合の階段上の床 -->
        <a-plane
          v-if="jsonData.modelName === 'outside'"
          id="outside-prevFloor"
          static-body
          position="0 1.25 -4"
          rotation="270 0 0"
          width="13"
          height="7.5"
          material="opacity:0"
        />
        <!-- アバター -->
        <a-entity
          v-if="pageId === undefined"
          id="avatar-parts"
        >
          <a-entity
            v-for="(avatar, index) in avatarData"
            :id="index"
            :key="index"
            :position="avatar.position"
            :rotation="avatar.rotation"
            @click="setConfirmWindow(index)"
          >
            <a-entity
              :gltf-model="avatar.model"
              rotation="0 180 0"
            />
            <a-video
              v-if="avatar.type === 'video'"
              :src="`#${avatar.face}`"
              position="0 1.03 0.175"
              width="0.6"
              height="0.34"
            />
          </a-entity>
        </a-entity>
      </a-scene>
    </div>
    <LoadingView v-if="!loadFlag" />
    <SoundPermission
      v-if="soundPermissionFlag"
      @sound-permission="setBgmStatus(true)"
    />
    <ConfirmWindow
      v-if="confirmWindowFlag"
      :target-url="targetUrl"
      :window-open="windowTabFlag"
      @close-window="confirmWindowFlag = false"
    />
  </div>
</template>

<script>
import utilsMixin from '../mixins/utils'
import LoadingView from '../components/LoadingView'
import SoundPermission from '../components/SoundPermission'
import ConfirmWindow from '../components/ConfirmWindow'
import RoomScene from '../components/RoomScene'
import settingJson from '../assets/setting-midorinokanshasai.json';

export default {
  name: 'MidorinokanshasaiView',
  components: {
    RoomScene,
    LoadingView,
    SoundPermission,
    ConfirmWindow
  },
  mixins: [utilsMixin],
  props: {},
  data() {
    return {
      title: 'みどりの感謝祭 メタバースで行こう！森の学校',  // ページタイトル
      description: 'みどりの感謝祭 メタバースで行こう！森の学校',  // ページ説明
      loadFlag: false,                                    // ロード画面用フラグ
      confirmWindowFlag: false,                           // 遷移先確認画面表示フラグ
      soundPermissionFlag: false,                         // 音声許可ウィンドウ表示フラグ
      targetUrl: '',                                      // 遷移先URL
      windowTabFlag: true,                                // 別タブ起動フラグ true->別タグ
      allJsonData: settingJson,                           // 全てのページの設定情報
      jsonData: '',                                       // ページごとの設定情報
      pageId: this.$route.params.page,                    // ページID
      prevPageId: this.$route.query.prev,                 // 一つ前のページID
      commonPath: '/assets/midorinokanshasai/',               // 各データの共通パス
      routePath: '/assets/midorinokanshasai/',                // 各データのルートパス
      secondaryRoutePath: '/assets/midorinokanshasai-page/',  // 子階層の各データのルートパス
      glbData: {},                                        // 子Vueで取得したGLBデータ
      delWarpGlbList: [],                                 // ワープゾーンの土台GLB(使っていないものは削除するために保持)
      faceList: {   // 顔動画
        reception: 'reception.mp4',
        wavehands: 'wavehands.mp4',
        talkingwoman: 'talkingwoman.mp4'
      },
      avatarData: { // アバターの情報
        'inquiry': {  // 受付アバター
          model: '/glb/simple-avatar/job-bus-guide.glb',
          type: 'video',
          face: 'reception',
          position: '-13.9 0 11.6',
          rotation: '0 90 0'
        },
        'questionnaire': {  // アンケート横アバター
          model: '/glb/simple-avatar/office-woman.glb',
          type: 'video',
          face: 'wavehands',
          position: '6.9 0 12.88',
          rotation: '0 -180 0'
        },
        'floormap': {  // フロアマップ横アバター
          model: '/glb/simple-avatar/casual-woman.glb',
          type: 'video',
          face: 'talkingwoman',
          position: '-5.5 0 0',
          rotation: '0 0 0'
        }
      },
      camera: {     // カメラ座標(全頁共通)
        position: {
          x: 0,
          y: 1.35,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 0
        }
      },
      pageRig: {    // 子階層ページのGLBごとのrig情報
        inside: {     // 室内用
          current: {  // 順路通りに来た場合
            position: {
              x: -7.4,
              y: 0,
              z: 11
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          },
          prev: {  // 前のページから戻ってきた場合
            position: {
              x: 7.6,
              y: 4.6,
              z: 12.3
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          }
        },
        outside: {    // 屋外用
          current: {  // 順路通りに来た場合
            position: {
              x: 0,
              y: 0,
              z: 8
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            }
          },
          prev: {  // 前のページから戻ってきた場合
            position: {
              x: -2.5,
              y: 2.6,
              z: -2.3
            },
            rotation: {
              x: 0,
              y: 270,
              z: 0
            }
          }
        }
      },
      menuContent: {
        logoPath: 'images/menu-logo.png       ',
        docUrl: 'https://midorinokanshasai.com/',
        contactUrl: 'https://midorinokanshasai.real-virtual.co.jp/#move'
      }
    }
  },
  computed: {
    firstAccess() {
      // 初回アクセスフラグを取得
      return this.$store.getters['CampusStatus/getFirstAccess'];
    },
    bgmStatus() {
      // BGM許可ステータスを取得
      return this.$store.getters['CampusStatus/getBgmStatus'];
    }
  },
  watch: {
    /*******************************************
     * BGM許可ステータスに変更がある場合
     ******************************************/
    async bgmStatus() {
      // BGMコントロール処理を呼び出し
      this.bgmControl();
    },
    /*******************************************
     * Loadフラグが更新されたらアニメーションを設定
     ******************************************/
    async loadFlag(newBool) {
      // 音声許可ポップアップを表示する判定
      if (this.firstAccess) {
        // 初回アクセスでiPhoneではない場合のみ設定
        if (!/[ \(]iP/.test(navigator.userAgent)) this.soundPermissionFlag = true;
      }
      if (newBool) {
        for (let property in this.glbData.animation) {
          // アニメーションを設定
          await this.setAnimation(property);
        }
        // リンク情報の存在しないワープGLBを非表示
        this.delWarpGlbList.forEach((elem, index) => {
          let removeGlbWarp = document.getElementById(elem);
          removeGlbWarp.setAttribute('visible', false);
        });
      }
    }
  },
  created() {
    // ページIDが存在しない場合eventZoneの処理を行う
    if (this.pageId === undefined) {
      // eventZoneの情報を設定
      this.jsonData = this.allJsonData.eventZone;
      // エレベーターから戻ってきた場合の出現位置を設定
      if (this.prevPageId !== undefined) {
        let prev = this.prevPageId.replace(/[^a-z]/gi, '');
        this.jsonData.rig = this.jsonData.prevRig[prev];
      }
    } else if (this.allJsonData[this.pageId] === undefined) {
      // ページが存在しない場合はメインフロアを表示
      this.jsonData = this.allJsonData.eventZone;
      this.$router.push({ name: 'midorinokanshasai' });
      setTimeout(function () {
        location.reload();
      }, 10);
    } else {
      // ルートパスを上書き
      this.routePath = this.secondaryRoutePath;
      // ページ情報を設定
      this.jsonData = this.allJsonData[this.pageId];
      // ページidから数字を抽出
      let prevNum = 0;
      let pageNum = this.pageId.replace(/[^0-9]/gi, '');
      if (this.prevPageId !== undefined) prevNum = this.prevPageId.replace(/[^0-9]/gi, '');
      // prevが現在のページより大きい数の場合rigを上書き
      if (pageNum < prevNum) {
        // 前のページから戻ってきた場合
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].prev;
      } else {
        // それ以外
        this.jsonData.rig = this.pageRig[this.jsonData.modelName].current;
      }
    }
    // カメラ座標を設定
    this.jsonData['camera'] = this.camera;
  },
  mounted() {
    // BGMコントロール処理を呼び出し
    this.bgmControl();
    // タイトルと説明、OGPを設定
    this.changePageContent();
    // ページがeventZoneの場合、顔動画を再生
    if (this.pageId === undefined) {
      for (const video in this.faceList) {
        let videoElement = document.getElementById(video);
        videoElement.play();
      }
    }
    /*******************************************
     * ワープゾーンのポジション設定
     ******************************************/
    const positionChanged = (newPosition) => {
      // 設定されているワープゾーンの数だけ繰り返しチェック
      for (let property in this.jsonData.warpList) {
        // 設定ファイルからワープゾーンのポジションを取得
        let xWarpSpot = Math.ceil(this.jsonData.warpList[property].position.x);
        let yWarpSpot = Math.ceil(this.jsonData.warpList[property].position.y);
        let zWarpSpot = Math.ceil(this.jsonData.warpList[property].position.z);
        // ワープゾーンに来たら確認ポップアップを起動(原点から±1m以内の範囲)
        if (
          (newPosition.x >= xWarpSpot - 1 && newPosition.x <= xWarpSpot + 1) &&
          (newPosition.y >= yWarpSpot - 1 && newPosition.y <= yWarpSpot + 1) &&
          (newPosition.z >= zWarpSpot - 1 && newPosition.z <= zWarpSpot + 1)
        ) {
          // ポップアップの処理を呼び出し
          this.setWarpConfirmWindow(property);
        }
      }
    };
    // ポジションを見張る処理
    window.AFRAME.registerComponent('position-listener', {
      tick() {
        // 現在のポジションを取得
        const newValue = this.el.getAttribute('position');
        // 小数点を四捨五入
        const newPosition = {
          x: Math.ceil(newValue.x),
          y: Math.ceil(newValue.y),
          z: Math.ceil(newValue.z)
        }
        // 文字列に置き換え
        const stringCoords = window.AFRAME.utils.coordinates.stringify({
          x: newPosition.x,
          y: newPosition.y,
          z: newPosition.z
        });
        // ポジションが変更しているかチェック
        if (this.lastValue !== stringCoords) {
          this.lastValue = stringCoords;
          // 変更がある場合はワープの位置をチェック
          positionChanged(newPosition);
        }
      }
    });
  },
  methods: {
    /*******************************************
     * BGM許可ステータスを設定
     ******************************************/
    setBgmStatus(newBool) {
      this.$store.dispatch('CampusStatus/setBgmStatus', newBool);
      this.soundPermissionFlag = false;
      // BGMコントロール処理を呼び出し
      this.bgmControl();
    },
    /*******************************************
     * フラグに変更があれば再生or一時停止処理
     ******************************************/
    async bgmControl() {
      const bgmSound = document.getElementById('bgmSound');
      if (Boolean(this.bgmStatus)) {
        // 再生処理
        try {
          // BGMを再生
          await bgmSound.play();
          // 初回アクセスの場合は書き換え
          if (this.firstAccess) this.$store.dispatch('CampusStatus/setFirstAccess', false);
        } catch (err) {
          // 再生に失敗した場合はフラグを書き換え
          this.setBgmStatus(false);
        }
      } else {
        // 一時停止処理
        bgmSound.pause();
      }
    },
    /*******************************************
     * 子から受け取った建物データを設定
     ******************************************/
    setGlbData(glbData) {
      this.glbData = glbData;
    },
    /*******************************************
     * 別タブでURLを起動
     ******************************************/
    openNewWindow(target) {
      window.open(target);
    },
    /*******************************************
     * 画面遷移のConfirm画面呼び出し
     ******************************************/
    setConfirmWindow(target) {
      // 設定ファイルからURLを取得
      console.log()
      this.targetUrl = this.jsonData.linkList[target].url;
      // confirmウィンドウを起動
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * ワープのConfirm画面呼び出し
     ******************************************/
    setWarpConfirmWindow(target) {
      // URL形式
      if (this.jsonData.warpList[target].url.indexOf('://') === -1) {
        // 自サイトの場合ホストを設定
        this.targetUrl = window.location.protocol + '//' + window.location.host + '/#';
        this.targetUrl += this.jsonData.warpList[target].url;
        // pageIDがある場合はパラメーターに設定
        if (this.pageId !== undefined) this.targetUrl += '?prev=' + this.pageId;
        this.windowTabFlag = false;
      } else {
        // 他サイトの場合はそのままURLを設定
        this.targetUrl = this.jsonData.warpList[target].url;
      }
      // confirmウィンドウを起動
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * パーツごとにアニメーションを設定する
     ******************************************/
    setAnimation(animationName) {
      let animationElement = document.getElementById(animationName);
      // ドア左の開くアニメーション
      // if (animationElement.id === 'animation-door-l') {
      //   animationElement.setAttribute('animation', 'property:object3D.position.x; to:-1.1; dir:alternate; delay:1000; dur:2000; loop:false;');
      // }
      // ドア右の開くアニメーション
      // if (animationElement.id === 'animation-door-r') {
      //   animationElement.setAttribute('animation', 'property:object3D.position.x; to:1.1; dir:alternate; delay:1000; dur:2000; loop:false');
      // }
      // 建物内の矢印を前後に動かす
      if (animationElement.id === 'animation-arrow') {
        animationElement.setAttribute('animation', 'property: object3D.position.z;to: -0.5;dir: alternate;dur: 1000;loop: true');
      }
      // ワープ(渦巻)のアニメーション
      if (animationElement.id.indexOf('animation-warp') !== -1) {
        // 原点にあるものは削除
        animationElement.remove();
        // glbファイルの保存先URLを取得
        const glbWarpPath = this.glbData.animation[animationElement.id].path;
        // warp glbのファイル名の最後の文字を取得(サイズが一致しない場合は表示しない判定に利用)
        const glbWarpSize = animationElement.id.charAt(animationElement.id.length - 1);
        // ワープゾーン(土台)のGLB名を全て取得(すでに設定されている場合は取得しない)
        if (this.delWarpGlbList.length === 0) {
          this.delWarpGlbList = Object.keys(this.glbData.common).filter((key) => {
            return key.match(/^nobody-warp-[0-9]/);
          });
        }
        // ワープGLBを配置する処理
        const scene = document.getElementById('scene');
        // 設定ファイルのワープリストが存在するだけ繰り返す
        for (let property in this.jsonData.warpList) {
          let warpData = this.jsonData.warpList[property];
          // ワープのサイズが一致しない場合は後続処理を行わない
          let warpSize = warpData.size.charAt(0);
          if (glbWarpSize !== warpSize) continue;
          // リンク先情報がある場合は削除リストから除外
          this.delWarpGlbList = this.delWarpGlbList.filter(function (glb) {
            return !glb.match(property);
          });
          // ワープのentityを作成して配置
          let warp = document.createElement('a-entity');
          warp.setAttribute('id', property);
          warp.setAttribute('static-body', 'shape:none;');
          warp.setAttribute('gltf-model', glbWarpPath);
          warp.setAttribute('position', warpData.position);
          warp.setAttribute('animation', 'property: rotation; from: 0 0 0; to: 0 360 0; dur: 800; easing: linear; loop: true');
          scene.appendChild(warp);
        }
      }
    }
  }
}
</script>

<style scoped>
.contents {
  height: 100vh;
}

.vr-scene {
  height: 92vh;
}

.top-menu {
  height: 8vh;
  display: flex;
}

.top-menu-right {
  position: relative;
  display: flex;
  margin: auto 10px auto auto;
}

.menu-button {
  background-color: #ffffff;
  margin: auto 10px auto 0;
  height: 50px;
  position: relative;
  line-height: 0.85em;
  cursor: pointer;
}

.button-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.button-content i {
  margin-bottom: 1px;
}

.button-content div {
  font-size: 0.35em;
  white-space: nowrap;
}

.box-btn {
  width: 100px;
  color: #239343;
  border: 2px solid #239343;
  border-radius: 15px;
}

.box-btn:hover {
  color: #fff;
  background-color: #239343;
}

.border-icon {
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid;
  border-radius: 50%;
  margin-bottom: 2px;
}

.circle-btn {
  width: 50px;
  color: #B19C64;
  border: thin solid #B19C64;
  border-radius: 50%;
}

.circle-btn:hover {
  color: #fff;
  background-color: #B19C64;
}

.circle-btn.volume--on {
  color: #fff;
  background-color: #B19C64;
}
</style>
